<script setup lang="ts">
import empty_video from '~/assets/image/empty/empty_video@2x.png'
import { delViewStorage } from '~/utils/utils'

// import girl1 from '~/assets/image/boon/girl1.png'
// import girl2 from '~/assets/image/boon/girl2.png'
// import girl3 from '~/assets/image/boon/girl3.png'
// import girl4 from '~/assets/image/boon/girl4.png'
import VIPIcon from '~/assets/image/icon/VIPIcon.png'

const onClickLeft = () => history.back()
const checked = ref([])
const checked1 = ref(false)
const checkboxGroup = ref()
const checkboxRefs = ref<any[]>([])
function checkAll() {
  console.log(checked1.value)
  if (checked1.value) {
    checkboxGroup.value.toggleAll(true)
  } else {
    checkboxGroup.value.toggleAll()
  }
}

const edit = ref(false)
const list = ref<any[]>([])
list.value = getViewStorage('browsHistory')
console.log(list.value)
// const list = [
//   {
//     img: girl1,
//     time: '22:06',
//     title: '异世界之风云雄霸AV界',
//     type: '影片',
//     id: '293425031152154',
//     num: '20',
//     isVip: true
//   },
//   {
//     img: girl2,
//     time: '22:06',
//     title: '异世界之风云雄霸AV界',
//     type: '影片',
//     id: '293425054',
//     num: '0',
//     isVip: false
//   },
//   {
//     img: girl3,
//     time: '22:06',
//     title: '异世界之风云雄霸AV界',
//     type: '影片',
//     id: '29342154',
//     num: '0',
//     isVip: true
//   },
//   {
//     img: girl4,
//     time: '22:06',
//     title: '异世界之风云雄霸AV界',
//     type: '影片',
//     id: '2154',
//     num: '10',
//     isVip: false
//   }
// ]

function choose(index: number) {
  checkboxRefs.value[index].toggle()
  console.log(checkboxRefs.value, checked.value)
  if (checked.value.length === list.value.length) {
    checked1.value = true
  } else {
    checked1.value = false
  }
}
const actions = [{ name: '确认删除' }]
const show = ref(false)
const type = ref('全部类型')
function onSelect(item: any) {
  show.value = false
  type.value = item.name
  const ids = checked.value.map((item) => {
    return item
  })
  console.log(ids)
  if (ids && ids.length)
  {
    delViewStorage('browsHistory', ids)
    list.value = getViewStorage('browsHistory')
  }
}
</script>

<template>
  <div h-screen ct-d0d0d0>
    <van-nav-bar title="我看过的" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="edit = !edit">
          {{ edit ? '取消' : '编辑' }}
        </button>
      </template>
    </van-nav-bar>
    <div v-if="list.length > 0">
      <van-checkbox-group ref="checkboxGroup" v-model="checked" px-4>
        <van-row :gutter="[20, 20]">
          <van-col v-for="(item, index) in list" :key="index" span="12">
            <div relative @click="choose(index)">
              <img v-if="item.isVip" :src="VIPIcon" absolute right-0 top-0 h-4 w-7 />
              <!-- <img h-28 w-42 :src="item.img" /> -->
              <ImgWrapper
                v-if="item.videoVerticalUrl" h-28
                w-42
                :src="item.videoVerticalUrl"
                :alt="item.videoName"
              />
              <div
                absolute
                bottom-0
                h-5
                w-full
                flex
                items-center
                justify-between
                pl-2
                pr-2
                text-xs
                ct-ffffff
                class="bg"
              >
                <van-checkbox
                  v-if="edit"
                  :ref="(el: any) => (checkboxRefs[index] = el)"
                  :name="item.videoId"
                  icon-size="15"
                  checked-color="#FD8203"
                  @click.stop
                />
                <span v-else>看过{{ item.playCountReal / item.playCountReal }}%</span>
                <span>{{ item.videoDuration }}</span>
              </div>
            </div>
            <div text-sm ct-ffffff>
              {{ item.videoName }}
            </div>
          </van-col>
        </van-row>
      </van-checkbox-group>
      <div v-if="edit" fixed bottom-0 h-12.5 w-full flex items-center justify-between pl-2 pr-2 bg-242943 ct-e8e8e8>
        <van-checkbox v-model="checked1" checked-color="#FD8203" @click="checkAll"> 全选 </van-checkbox>
        <span @click="show = true">删除本地文件</span>
      </div>
    </div>
    <div v-else h-2xl flex flex-col items-center justify-center px-4>
      <img h-37 w-50 :src="empty_video" />
      <div mt-3 text-xs ct-ffffff>暂时没有观看记录</div>
    </div>
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
:deep(.van-checkbox__label) {
  color: #e8e8e8;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-action-sheet__content) {
  background: #414660;
}
</style>
